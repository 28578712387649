import { FilmListUser } from "../@types/definitions";
import { useAppSelector } from "../hooks/reduxAlias";
import { ListsScreen } from "./lists-screen";
import { WelcomeScreen } from "./welcome-screen";

const ListsScreenRouter = () => {
  const user = useAppSelector(state => state.user.user) as FilmListUser;

  if (user.id === null) {
    return <WelcomeScreen />;
  }

  return <ListsScreen />;
};

export { ListsScreenRouter };
