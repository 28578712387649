export enum ListActions {
  CreateNewList = "CREATE_NEW_LIST",
  AddMovieToList = "ADD_MOVIE_TO_LIST",
  EditMovie = "EDIT_MOVIE",
  RemoveMovieFromList = "REMOVE_MOVIE_FROM_LIST",
  RemoveList = "REMOVE_LIST",
  ToggleMovieWatchedStatus = "TOGGLE_MOVIE_WATCHD_STATUS",
  EditList = "EDIT_LIST",
  UpdateListId = "UPDATE_LIST_ID",
  UpdateListSynced = "UPDATE_LIST_SYNCED",
  LoadLists = "LOAD_LISTS",
  UpdateLists = "UPDATE_LISTS",
  DeleteLists = "DELETE_LISTS",
  UpdateMovie = "UPDATE_MOVIE",
}

export enum ListSettingActions {
  ChangeListSortType = "CHANGE_LIST_SORT_TYPE",
  ChangeListSortDirection = "CHANGE_LIST_SORT_DIRECTION",
}

export enum ModalAction {
  OpenModal = "OPEN_MODAL",
  CloseModal = "CLOSE_MODAL",
}

export enum LoaderAction {
  ListsAreSyncing = "LISTS_START_SYNCING",
  ListsStoppedSyncing = "LISTS_STOPPED_SYNCING",
}

export enum UserAction {
  LogIn = "LOG_USER_IN",
  LogOut = "LOG_USER_OUT",
  AuthHasInit = "AUTH_HAS_INIT",
}

export enum UserSettingsAction {
  AddPreferedProvider = "ADD_PREFERED_PROVIDER",
  RemovePreferedProvider = "REMOVE_PREFERED_PROVIDER",
  UpdateSettingsSynced = "UPDATE_SETTINGS_SYNCED",
  LoadSettings = "LOAD_SETTINGS",
}

export enum StorageKeys {
  Lists = "myfilmlist_lists",
  ListSettings = "myfilmlist_list-settings",
  UserSetings = "myfilmlist_user-settings",
}

export enum MovieSortOption {
  Runtime = "Runtime",
  Added = "Added",
  ReleaseDate = "Release Date",
}

export enum SortDirection {
  Ascending = "ASC",
  Descending = "DESC",
}

export enum ModalIdentifier {
  AddMovieModal = "ADD_MOVIE_MODAL",
  EditMovieModal = "EDIT_MOVIE_MODAL",
  EditListModal = "EDIT_LIST_MODAL",
  ProfileSettingsModal = "PROFILE_SETTINGS_MODAL",
}
