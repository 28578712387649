import { useEffect } from "react";
import { EditMovieData, Movie } from "../../@types/definitions";
import {
  editMovie,
  removeMovieFromList,
  updateMovie,
} from "../../actions/list";
import { Modal } from "../../components/modal/modal";
import { MovieForm } from "../../components/movie-form/movie-form";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxAlias";
import { useModal } from "../../hooks/useModal";
import { getMovieDetailsForEdit } from "../../selectors/lists";

const EditMovieModal = () => {
  const { listId } = useAppSelector(state => state.modal.data) as EditMovieData;
  const initialMovie = useAppSelector(getMovieDetailsForEdit);
  const dispatch = useAppDispatch();
  const { closeModal } = useModal();

  const onSave = (movie: Movie) => {
    dispatch(editMovie(listId, movie));
    closeModal();
  };

  const onDelete = (movieId: number, movieName: string) => {
    if (
      window.confirm(
        "Are you sure you want to remove " + movieName + " from your list?",
      )
    ) {
      closeModal();
      dispatch(removeMovieFromList(listId, movieId));
    }
  };

  useEffect(() => {
    if (initialMovie) {
      dispatch(updateMovie(listId, initialMovie.movieDbId));
    }
  }, [dispatch, initialMovie, listId]);

  if (!initialMovie) {
    return null;
  }

  return (
    <Modal title="Movie Details">
      <MovieForm
        id={initialMovie.movieDbId}
        name={initialMovie.name}
        releaseDate={initialMovie.releaseDate}
        imageUrl={initialMovie.imageUrl}
        runtime={initialMovie.runtime}
        added={initialMovie.added}
        watchDate={initialMovie.watchDate}
        providers={initialMovie.providers}
        updated={initialMovie.updated}
        onSave={onSave}
        onDelete={onDelete}
        saveText="Save"
        needToSelectList={false}
      />
    </Modal>
  );
};

export { EditMovieModal };
