import "./content-container.scss";

interface ContentContainerProps {
  children: any;
}

const ContentContainer = (props: ContentContainerProps) => {
  const { children } = props;
  return <div className="content-container">{children}</div>;
};

export { ContentContainer };
