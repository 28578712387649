import { createStore, compose, applyMiddleware, AnyAction } from "redux";
import { allReducers } from "./reducers";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { saveToStorage } from "./middleware/storage";
import { initialState } from "./initial-state";
import thunk, { ThunkDispatch } from "redux-thunk";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: any;
  }
}

//window.__REDUX_DEVTOOLS_EXTENSION__ = window.__REDUX_DEVTOOLS_EXTENSION__ ?? {};

export const history = createBrowserHistory();
export const store = createStore(
  allReducers(history),
  initialState,
  compose(
    applyMiddleware(thunk, routerMiddleware(history), saveToStorage),
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
      process.env.NODE_ENV === "development"
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (a: any) => a,
  ),
);

export type State = ReturnType<typeof store.getState>;
export type Dispatch = ThunkDispatch<State, unknown, AnyAction>;
