import React, { useMemo, useState } from "react";
import { EditMovieData, ListWithSettings, Movie } from "../@types/definitions";
import {
  changeListSortDIrection,
  changeListSortType,
  toggleMovieWatchedStatus,
} from "../actions/list";
import { ContentContainer } from "../components/content-container/content-container";
import { Header } from "../components/header/header";
import { MovieList } from "../components/movie-list/movie-list";
import { Section } from "../components/section/section";
import { SortBar } from "../components/sort-bar/sort-bar";
import { ModalIdentifier, MovieSortOption, SortDirection } from "../constants";
import { useModal } from "../hooks/useModal";
import { partition } from "lodash";
import { HeaderToggle } from "../components/header-toggle/header-toggle";
import { AddFilmButton } from "../components/add-film-button/add-film-button";
import { EditListData } from "../modals/edit-list/edit-list";
import {
  sortMoviesByDateAdded,
  sortMoviesByReleaseDate,
  sortMoviesByRuntime,
} from "../helpers/sorting";
import { useAppDispatch } from "../hooks/reduxAlias";

interface ListScreenProps {
  list: ListWithSettings;
}

const ListScreen = (props: ListScreenProps) => {
  const { list } = props;
  const [showWatched, setShowWatched] = useState(false);
  const { openModal } = useModal();
  const dispatch = useAppDispatch();
  const { id, name, activeSortType, movies: unFilteredMovies } = list;

  const [movies, watchedMovies] = partition(
    unFilteredMovies,
    movie => movie.watchDate === undefined,
  );

  const onEditMovie = (movie: Movie) => {
    const data: EditMovieData = {
      listId: id,
      movie,
    };

    openModal(ModalIdentifier.EditMovieModal, data);
  };

  const onToggleWatchedMovie = (movieId: number) => {
    dispatch(toggleMovieWatchedStatus(id, movieId));
  };

  const onSortDirectionChange = () => {
    if (activeSortType.direction === SortDirection.Ascending) {
      dispatch(changeListSortDIrection(id, SortDirection.Descending));
    } else {
      dispatch(changeListSortDIrection(id, SortDirection.Ascending));
    }
  };

  const onSortTypeChange = (type: MovieSortOption) => {
    dispatch(changeListSortType(id, type));
  };

  const sortedMovies = useMemo(() => {
    switch (activeSortType.type) {
      case MovieSortOption.Added:
        return sortMoviesByDateAdded(movies, activeSortType.direction);
      case MovieSortOption.Runtime:
        return sortMoviesByRuntime(movies, activeSortType.direction);
      case MovieSortOption.ReleaseDate:
        return sortMoviesByReleaseDate(movies, activeSortType.direction);
      default:
        return movies;
    }
  }, [activeSortType.type, activeSortType.direction, movies]);

  const sortedWatched = useMemo(() => {
    return [...watchedMovies].sort((a, b) => {
      if (a.watchDate !== undefined && b.watchDate !== undefined) {
        return a.watchDate - b.watchDate;
      } else {
        return 0;
      }
    });
  }, [watchedMovies]);

  return (
    <>
      <Header
        backLink={"/"}
        moreButtonAction={() =>
          openModal(ModalIdentifier.EditListModal, {
            listId: id,
          } as EditListData)
        }
      >
        {name}
      </Header>
      <Section>
        <ContentContainer>
          <SortBar
            activeSortOption={activeSortType.type}
            onSortOptionPress={onSortTypeChange}
            activeDirection={activeSortType.direction}
            onSortDirectionPress={onSortDirectionChange}
          />
        </ContentContainer>
        <ContentContainer>
          {movies.length > 0 ? (
            <MovieList
              movies={sortedMovies}
              onEditMovie={onEditMovie}
              onToggleWatched={onToggleWatchedMovie}
            />
          ) : (
            <p>
              {watchedMovies.length > 0
                ? "You've watched all movies in this list!"
                : "You haven't added any movies to this list"}
            </p>
          )}
        </ContentContainer>
        {sortedWatched.length > 0 && (
          <ContentContainer>
            <HeaderToggle
              isOpen={showWatched}
              onPress={() => setShowWatched(watched => !watched)}
            >
              Watched ({sortedWatched.length})
            </HeaderToggle>
            {showWatched && (
              <MovieList
                movies={sortedWatched}
                onEditMovie={onEditMovie}
                onToggleWatched={onToggleWatchedMovie}
              />
            )}
          </ContentContainer>
        )}
        <AddFilmButton listId={id} />
      </Section>
    </>
  );
};

export { ListScreen };
