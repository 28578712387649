import React, { useState } from "react";
import { List } from "../../@types/definitions";
import { editList, removeList } from "../../actions/list";
import { Button, ButtonIcon, ButtonType } from "../../components/button/button";
import { ModalFooter } from "../../components/modal-footer/modal-footer";
import { Modal } from "../../components/modal/modal";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxAlias";
import { useLink } from "../../hooks/useLink";
import { useModal } from "../../hooks/useModal";
import "./edit-list.scss";

export interface EditListData {
  listId: string;
}

export interface EditListModalProps {
  list: List;
}

const EditListModal = (props: EditListModalProps) => {
  const { list } = props;
  const { id, name } = list;
  const [editedName, setEditedName] = useState(name);
  const dispatch = useAppDispatch();
  const { closeModal } = useModal();
  const goToScreen = useLink();

  const onEditList = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (editedName.trim().length > 0) {
      dispatch(editList(id, { name: editedName }));
      closeModal();
    } else {
      window.alert("That name isn't valid");
      setEditedName(name);
    }
  };

  const onDeleteList = () => {
    if (window.confirm("Are you sure you want to delete this list?")) {
      goToScreen("/");
      closeModal();
      dispatch(removeList(id));
    }
  };

  return (
    <Modal title="Edit List">
      <form onSubmit={onEditList}>
        <label className="edit-list-modal__field">
          <span className="edit-list-modal__field-label">Name</span>
          <input
            type="text"
            className="edit-list-modal__field-input"
            onChange={e => setEditedName(e.target.value)}
            value={editedName}
          />
        </label>
        <ModalFooter>
          <Button
            onPress={onDeleteList}
            icon={ButtonIcon.Bin}
            type={ButtonType.SecondaryWarning}
          >
            Delete
          </Button>
          <Button onPress="submit" icon={ButtonIcon.Save}>
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

const EditListModalRouter = () => {
  const { listId } = useAppSelector(state => state.modal.data) as EditListData;
  const lists = useAppSelector(state => state.lists.lists) as Array<List>;
  const list = lists.find(list => list.id === listId);

  if (list === undefined) {
    return null;
  }

  return <EditListModal list={list} />;
};

export { EditListModalRouter as EditListModal };
