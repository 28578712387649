import { List, ListApiResponse, ListSettings } from "../@types/definitions";
import slugify from "slugify";
import { createRandomId } from "./ids";
import { MovieSortOption, SortDirection } from "../constants";

export const getAppropriateSlugForNewList = (
  newListName: string,
  lists: Array<List>,
): string => {
  const existingSlugs = lists.map(list => list.slug);
  let newSlug = slugify(newListName).toLowerCase();
  let counter = 1;
  let foundSlug = false;

  if (!existingSlugs.includes(newSlug)) {
    return newSlug;
  }

  while (!foundSlug) {
    const trySlug = newSlug + "-" + counter.toString();
    if (existingSlugs.includes(trySlug)) {
      counter++;
    } else {
      foundSlug = true;
      newSlug = trySlug;
    }
  }

  return newSlug;
};

export const generateNewList = (name: string, slug: string): List => {
  const newId = createRandomId();
  return {
    id: newId,
    name,
    slug,
    movies: [],
    synced: null,
  };
};

export const convertListFromDatabaseToList = (
  listFromDb: ListApiResponse,
): List => {
  const {
    ts,
    data: { owner, ...rest },
  } = listFromDb;
  return {
    ...rest,
    synced: ts,
  };
};

export const generateNewListSettings = (listId: string): ListSettings => {
  return {
    listId,
    activeSortType: {
      type: MovieSortOption.Added,
      direction: SortDirection.Ascending,
    },
  };
};
