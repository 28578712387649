import { AnyAction } from "redux";
import { ModalAction, ModalIdentifier } from "../constants";

interface ModalState {
  active: ModalIdentifier | null;
  data: any;
}

const defaultModal: ModalState = {
  active: null,
  data: null,
};

export const modal = (
  state: ModalState = { ...defaultModal },
  action: AnyAction,
) => {
  switch (action.type) {
    case ModalAction.OpenModal:
      return {
        ...state,
        active: action.modalId,
        data: action.data,
      };
    case ModalAction.CloseModal:
      return {
        ...defaultModal,
      };
    default:
      return state;
  }
};
