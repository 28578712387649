import classnames from "classnames";
import "./button.scss";
import { ReactComponent as PlusIcon } from "@fortawesome/fontawesome-free/svgs/solid/plus.svg";
import { ReactComponent as BinIcon } from "@fortawesome/fontawesome-free/svgs/solid/trash-alt.svg";
import { ReactComponent as SaveIcon } from "@fortawesome/fontawesome-free/svgs/solid/save.svg";
import { ReactComponent as LogoutIcon } from "@fortawesome/fontawesome-free/svgs/solid/sign-out-alt.svg";

export enum ButtonIcon {
  Plus = "Plus",
  Bin = "Bin",
  Save = "Save",
  Logout = "Logout",
}

export enum ButtonType {
  Primary = "Primary",
  Secondary = "Secondary",
  SecondaryWarning = "Secondary_Warning",
}

export enum ButtonSize {
  Default = "Default",
  Large = "Large",
}

interface ButtonProps {
  children: string;
  icon?: ButtonIcon;
  type?: ButtonType;
  size?: ButtonSize;
  onPress: "submit" | (() => any);
}

const Button = (props: ButtonProps) => {
  const { children, icon, type, size, onPress } = props;
  const displayType = type ?? ButtonType.Primary;
  const displaySize = size ?? ButtonSize.Default;

  const classes = classnames("btn", {
    "btn--primary": displayType === ButtonType.Primary,
    "btn--secondary":
      displayType === ButtonType.Secondary ||
      displayType === ButtonType.SecondaryWarning,
    "btn--warning": displayType === ButtonType.SecondaryWarning,
    "btn--large": displaySize === ButtonSize.Large,
  });

  const displayIcon = (): JSX.Element | null => {
    switch (icon) {
      case ButtonIcon.Bin:
        return <BinIcon className="btn__icon" />;
      case ButtonIcon.Plus:
        return <PlusIcon className="btn__icon" />;
      case ButtonIcon.Save:
        return <SaveIcon className="btn__icon" />;
      case ButtonIcon.Logout:
        return <LogoutIcon className="btn__icon" />;
      default:
        return null;
    }
  };

  return (
    <button
      className={classes}
      onClick={onPress === "submit" ? undefined : onPress}
      type={onPress === "submit" ? "submit" : "button"}
    >
      {displayIcon()}
      {children}
    </button>
  );
};

export { Button };
