import { ChangeEvent, useEffect, useState } from "react";
import { MovieProvider } from "../../@types/definitions";
import {
  addPreferedProvider,
  removePreferedProvider,
} from "../../actions/user";
import { getImageUrlForList } from "../../helpers/images";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxAlias";
import { Loader } from "../loader/loader";
import "./provider-selection.scss";

const ProviderSelection = () => {
  const [providers, setProviders] = useState<Array<MovieProvider>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const preferedProviders = useAppSelector(
    state => state.user.settings.providers,
  );
  const dispatch = useAppDispatch();

  const onToggle = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      dispatch(addPreferedProvider(parseInt(e.target.value)));
    } else {
      dispatch(removePreferedProvider(parseInt(e.target.value)));
    }
  };

  useEffect(() => {
    window
      .fetch("/.netlify/functions/get-providers")
      .then(response => response.json())
      .then(data => {
        setProviders(data.results);
      })
      .finally(() => setIsLoading(false));
  }, [setProviders, setIsLoading]);

  return (
    <div className="providers-selection">
      {isLoading && (
        <div className="providers-selection__loader-container">
          <Loader />
        </div>
      )}
      {providers.length > 0 && (
        <ul className="provider-selection__list">
          {providers.map(({ provider_id, provider_name, logo_path }) => {
            return (
              <li className="providers-selection__list-item" key={provider_id}>
                <label className="providers-selection__list-item-label">
                  <img
                    src={getImageUrlForList(logo_path)}
                    className="providers-selection__list-item-image"
                    alt={provider_name}
                  />
                  <span className="providers-selection__list-item-content">
                    {provider_name}
                  </span>
                  <div className="providers-select__checkbox">
                    <input
                      type="checkbox"
                      value={provider_id}
                      checked={preferedProviders?.includes(provider_id)}
                      onChange={onToggle}
                    />
                  </div>
                </label>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export { ProviderSelection };
