import { ReactComponent as DownChevronIcon } from "@fortawesome/fontawesome-free/svgs/solid/chevron-down.svg";
import { ReactComponent as UpChevronIcon } from "@fortawesome/fontawesome-free/svgs/solid/chevron-up.svg";
import "./header-toggle.scss";

interface HeaderToggleProps {
  isOpen: boolean;
  children: any;
  onPress: () => void;
}

const HeaderToggle = (props: HeaderToggleProps) => {
  const { isOpen, onPress, children } = props;

  return (
    <button className="header-toggle" onClick={onPress}>
      <h3 className="header-toggle__title">{children}</h3>
      <span className="header-toggle__divider" role="presentation" />
      {isOpen ? (
        <UpChevronIcon className="header-toggle__icon" />
      ) : (
        <DownChevronIcon className="header-toggle__icon" />
      )}
    </button>
  );
};

export { HeaderToggle };
