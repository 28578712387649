import { Movie } from "../@types/definitions";
import { SortDirection } from "../constants";
import { convertReleaseDateToTime } from "./dates";

export const sortMoviesByDateAdded = (
  movies: Array<Movie>,
  direction: SortDirection,
): Array<Movie> => {
  return [...movies].sort((a: Movie, b: Movie) => {
    let sortVal = a.added - b.added;
    if (direction === SortDirection.Descending) {
      sortVal = sortVal * -1;
    }
    return sortVal;
  });
};

export const sortMoviesByRuntime = (
  movies: Array<Movie>,
  direction: SortDirection,
): Array<Movie> => {
  return [...movies].sort((a: Movie, b: Movie) => {
    if (a.runtime === null || b.runtime === null) {
      return 0;
    }
    let sortVal = a.runtime - b.runtime;
    if (direction === SortDirection.Descending) {
      sortVal = sortVal * -1;
    }
    return sortVal;
  });
};

export const sortMoviesByReleaseDate = (
  movies: Array<Movie>,
  direction: SortDirection,
): Array<Movie> => {
  return [...movies].sort((a: Movie, b: Movie) => {
    if (a.releaseDate !== undefined && b.releaseDate !== undefined) {
      let sortVal =
        convertReleaseDateToTime(a.releaseDate) -
        convertReleaseDateToTime(b.releaseDate);
      if (direction === SortDirection.Descending) {
        sortVal = sortVal * -1;
      }
      return sortVal;
    }
    return 0;
  });
};
