import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store";
import * as NetlifyIdentityWidget from "netlify-identity-widget";
import { authHasInit, loginUser, logoutUser } from "./actions/user";
import { FilmListUser } from "./@types/definitions";

NetlifyIdentityWidget.on("init", user => {
  if (user !== null) {
    store.dispatch(loginUser(user));
    NetlifyIdentityWidget.refresh();
  } else {
    store.dispatch(authHasInit());
  }
});

NetlifyIdentityWidget.on("login", user => {
  const loggedInUser: FilmListUser = store.getState().user.user;
  if (loggedInUser.id === null) {
    store.dispatch(loginUser(user));
    NetlifyIdentityWidget.close();
  }
});

NetlifyIdentityWidget.on("logout", () => {
  store.dispatch(logoutUser());
});

NetlifyIdentityWidget.init();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
