import * as NetlifyIdentityWidget from "netlify-identity-widget";
import { Button, ButtonType } from "../button/button";
import "./welcome.scss";

const Welcome = () => {
  return (
    <div className="welcome">
      <div className="welcome__card">
        <h1 className="welcome__title">My Film List</h1>
        <p>
          Organise your watch lists and keep track of streaming service
          availability.
        </p>
        <div className="welcome__footer">
          <Button onPress={() => NetlifyIdentityWidget.open("signup")}>
            Register
          </Button>
          <Button
            type={ButtonType.Secondary}
            onPress={() => NetlifyIdentityWidget.open("login")}
          >
            Have an account? Sign in.
          </Button>
        </div>
        <div className="welcome__attributions">
          My Film List uses{" "}
          <a
            href="https://www.themoviedb.org"
            rel="noopener noreferrer"
            target="_blank"
          >
            TMDb
          </a>{" "}
          API. Streaming service information provided by{" "}
          <a
            href="https://www.justwatch.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            JustWatch
          </a>
          .
        </div>
      </div>
    </div>
  );
};

export { Welcome };
