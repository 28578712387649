import { useLink } from "../../hooks/useLink";

interface LinkProps {
  link: string;
  children: any;
  className?: string;
}

const Link = ({ link, children, className }: LinkProps) => {
  const goToScreen = useLink();
  const onClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    goToScreen(link);
  };

  return (
    <a href={link} className={className} onClick={onClick}>
      {children}
    </a>
  );
};

export { Link };
