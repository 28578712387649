import { ModalAction, ModalIdentifier } from "../constants";

export const openModal = (modalId: ModalIdentifier, data: any = null) => ({
  type: ModalAction.OpenModal,
  modalId,
  data,
});

export const closeModal = () => ({
  type: ModalAction.CloseModal,
});
