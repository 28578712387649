import { push } from "connected-react-router";
import { useState } from "react";
import { List, Movie, MovieDbMovie } from "../../@types/definitions";
import { addMovieToList } from "../../actions/list";
import { Modal } from "../../components/modal/modal";
import { MovieForm } from "../../components/movie-form/movie-form";
import { MovieSearch } from "../../components/movie-search/movie-search";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxAlias";
import { useModal } from "../../hooks/useModal";

export interface AddMovieData {
  listId: string | null;
}

const AddMovieModal = () => {
  const dispatch = useAppDispatch();
  const [movie, setMovie] = useState<MovieDbMovie | null>(null);
  const { listId: initialListId } = useAppSelector(
    state => state.modal.data,
  ) as AddMovieData;
  const lists = useAppSelector(state => state.lists.lists) as Array<List>;
  const [listId, setListId] = useState<string | null>(initialListId);
  const { closeModal } = useModal();
  const list = lists.find(list => list.id === listId);

  const onSave = (movie: Movie) => {
    if (!listId) {
      window.alert("You haven't selected a list to add to.");
      return;
    }

    if (
      list?.movies.find(
        listMovie => listMovie.movieDbId === movie.movieDbId,
      ) === undefined
    ) {
      dispatch(addMovieToList(listId, movie));
      dispatch(push("/list/" + list?.slug + "#" + movie.movieDbId));
    } else {
      window.alert(movie.name + " is already in your list.");
    }
    closeModal();
  };

  return (
    <Modal title="Add Film">
      {movie === null ? (
        <MovieSearch onChoose={setMovie} />
      ) : (
        <MovieForm
          id={movie.id}
          name={movie.title}
          releaseDate={movie.release_date}
          runtime={movie.runtime}
          imageUrl={movie.poster_path}
          providers={movie.providers}
          onSave={onSave}
          needToSelectList={initialListId === null}
          onSelectList={setListId}
          activeList={listId}
        />
      )}
    </Modal>
  );
};

export { AddMovieModal };
