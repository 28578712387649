import "./modal-footer.scss";

interface ModalFooterProps {
  children: any;
}

const ModalFooter = (props: ModalFooterProps) => {
  const { children } = props;
  return <div className="modal-footer">{children}</div>;
};

export { ModalFooter };
