import React, { useEffect } from "react";
import { ConnectedRouter, push } from "connected-react-router";
import { Switch, Route } from "react-router";
import { history } from "./store";
import { ModalManager } from "./modal-manager";
import { ListScreenRouter } from "./screens/list-screen-router";
import { UserState } from "./reducers/user";
import { Loader } from "./components/loader/loader";
import { ListsScreenRouter } from "./screens/lists-screen-router";
import "./styles/global.scss";
import { loadListsFromDatabase } from "./actions/list";
import { SyncIndicator } from "./components/sync-indicator/sync-indicator";
import { useAppDispatch, useAppSelector } from "./hooks/reduxAlias";

const App = () => {
  const { user, authInit } = useAppSelector(state => state.user) as UserState;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (authInit && user.id === null) {
      dispatch(push("/"));
    } else if (authInit && user.id !== null) {
      dispatch(loadListsFromDatabase());
    }
  }, [user, authInit, dispatch]);

  if (!authInit) {
    return (
      <div className="full-screen-loader">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div style={{ position: "relative", zIndex: 1 }}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/list/:id" component={ListScreenRouter} />
            <Route component={ListsScreenRouter} />
          </Switch>
        </ConnectedRouter>
      </div>
      <ModalManager />
      <div className="fixed-loading-indicator-container">
        <SyncIndicator />
      </div>
    </>
  );
};

export default App;
