import { List } from "../@types/definitions";
import { openModal } from "../actions/modal";
import { AddFilmButton } from "../components/add-film-button/add-film-button";
import { ContentContainer } from "../components/content-container/content-container";
import { Header, HeaderMoreIcon } from "../components/header/header";
import { Lists } from "../components/lists/lists";
import { Section } from "../components/section/section";
import { ModalIdentifier } from "../constants";
import { useAppDispatch, useAppSelector } from "../hooks/reduxAlias";

const ListsScreen = () => {
  const lists = useAppSelector(state => state.lists.lists) as Array<List>;
  const dispatch = useAppDispatch();

  const openProfileSettings = () => {
    dispatch(openModal(ModalIdentifier.ProfileSettingsModal));
  };

  return (
    <>
      <Header
        moreButtonAction={openProfileSettings}
        moreIcon={HeaderMoreIcon.UserSettings}
      >
        Lists
      </Header>
      <Section>
        <ContentContainer>
          <Lists lists={lists} />
        </ContentContainer>
        <AddFilmButton listId={null} />
      </Section>
    </>
  );
};

export { ListsScreen };
