import React, { useCallback, useEffect, useState } from "react";
import { List } from "../../@types/definitions";
import { createNewList } from "../../actions/list";
import { useAppDispatch } from "../../hooks/reduxAlias";
import { Button, ButtonIcon, ButtonType } from "../button/button";
import { Link } from "../link/link";
import "./lists.scss";

interface ListProps {
  lists: Array<List>;
}

const Lists = (props: ListProps) => {
  const { lists } = props;
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [newName, setNewName] = useState("");
  const dispatch = useAppDispatch();

  const createList = () => {
    if (newName.trim().length > 0) {
      dispatch(createNewList(newName));
    }
    setNewName("");
    setIsAddingNew(false);
  };

  const onSubmitNew = (e: React.SyntheticEvent) => {
    e.preventDefault();
    createList();
  };

  const newInput = useCallback(node => {
    if (node !== null) {
      node.focus();
    }
  }, []);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape" || e.key === "Esc") {
        setIsAddingNew(false);
      }
    };

    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [setIsAddingNew]);

  return (
    <>
      <ul className="lists_list">
        {lists.map(list => {
          const { id, name, slug, movies } = list;
          const watchedCount = movies.filter(
            movie => movie.watchDate !== undefined,
          ).length;
          const watchCount = movies.length - watchedCount;
          const link = "/list/" + slug;
          return (
            <li className="lists__list-item" key={id}>
              <Link link={link} className="lists__list-link">
                <>
                  <h3 className="lists__list-item-title">{name}</h3>
                  <div className="lists__list-item-details">
                    <span className="lists__list-item-meta">
                      {watchCount} film
                      {watchCount !== 1 && "s"} to watch
                    </span>
                  </div>
                </>
              </Link>
            </li>
          );
        })}
        {isAddingNew && (
          <li className="lists__list-item">
            <form className="lists__list-new-form" onSubmit={onSubmitNew}>
              <input
                type="text"
                ref={newInput}
                className="lists__list-new-input"
                onBlur={createList}
                onChange={e => setNewName(e.target.value)}
                value={newName}
                placeholder="Give your list a name..."
              />
              <Button onPress="submit" icon={ButtonIcon.Plus}>
                Add
              </Button>
            </form>
          </li>
        )}
      </ul>
      {!isAddingNew && (
        <Button
          icon={ButtonIcon.Plus}
          type={ButtonType.Secondary}
          onPress={() => setIsAddingNew(true)}
        >
          Create New List
        </Button>
      )}
    </>
  );
};

export { Lists };
