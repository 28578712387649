import "./list-item-meta-details.scss";

interface ListItemMetaDetailsProps {
  meta: Array<string>;
}

const ListItemMetaDetails = ({ meta }: ListItemMetaDetailsProps) => {
  return (
    <div className="list-item-meta-details">
      <ul className="list-item-meta-details__list">
        {meta.map((metaItem, index) => (
          <li key={index} className="list-item-meta-details__list-item">
            {metaItem}
          </li>
        ))}
      </ul>
    </div>
  );
};

export { ListItemMetaDetails };
