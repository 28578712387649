import { useEffect, useState, useRef, useCallback } from "react";
import {
  MovieDbMovie,
  MovieDbSearchResult,
  PromiseWithCancel,
} from "../../@types/definitions";
import { ReactComponent as SearchIcon } from "@fortawesome/fontawesome-free/svgs/solid/search.svg";
import "./movie-search.scss";
import { Loader } from "../loader/loader";
import { convertReleaseDateToYear } from "../../helpers/dates";
import { getImageUrlForList } from "../../helpers/images";
import { ListItemMetaDetails } from "../list-item-meta-details/list-item-meta-details";
import { fetchSearchResults, isAbortError } from "../../helpers/api";

interface MovieSearchProps {
  onChoose: (a: MovieDbMovie | null) => void;
}

const MovieSearch = (props: MovieSearchProps) => {
  const { onChoose } = props;

  const searchDebuff = useRef<number | undefined>();
  const searchQuery = useRef<PromiseWithCancel<any> | undefined>();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<Array<MovieDbSearchResult>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const focusOnSearch = useCallback(node => {
    if (node !== null) {
      node.focus();
    }
  }, []);

  const chooseMovie = (result: MovieDbSearchResult) => {
    const { id } = result;
    setIsLoading(true);

    window
      .fetch("/.netlify/functions/movie?id=" + id.toString())
      .then(response => response.json())
      .then(data => {
        onChoose(data.movie as MovieDbMovie);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    window.clearTimeout(searchDebuff.current);
    searchQuery.current?.cancel();

    if (query.length > 0) {
      setIsLoading(true);
      searchDebuff.current = window.setTimeout(() => {
        searchQuery.current = fetchSearchResults(query);
        searchQuery.current
          .then(setResults)
          .finally(() => setIsLoading(false))
          .catch(err => {
            if (!isAbortError(err)) {
              window.alert("Something went wrong!");
            }
          });
      }, 500);
    } else {
      setIsLoading(false);
    }

    return () => {
      searchQuery.current?.cancel();
      window.clearTimeout(searchDebuff.current);
    };
  }, [query, setIsLoading]);

  return (
    <div className="movie-search">
      <form className="movie-search__search">
        <label className="movie-search__search-input">
          <span className="movie-search__search-label">Search</span>
          <input
            type="text"
            ref={focusOnSearch}
            onChange={e => setQuery(e.target.value)}
            value={query}
            className="movie-search__search-input-field"
            placeholder="Search..."
          />
        </label>
        <button type="submit" className="movie-search__search-btn">
          <SearchIcon className="movie-search__search-btn-icon" />
        </button>
      </form>
      <div className="movie-search__results">
        {query.length === 0 && (
          <div className="movie-search__results-message">
            <p>Enter a term to starting searching.</p>
          </div>
        )}
        {query.length > 0 && results.length === 0 && !isLoading && (
          <div className="movie-search__results-message">
            <p>No results found!</p>
          </div>
        )}
        {query.length > 0 && results.length > 0 && (
          <ul className="movie-search__results-list">
            {results.map((result: MovieDbSearchResult) => (
              <li key={result.id} className="movie-search__results-list-item">
                <button
                  className="movie-search__results-list-item-pressable"
                  onClick={() => chooseMovie(result)}
                >
                  <div className="movie-search__results-list-item-image-container poster-image-container">
                    {result.poster_path !== null && (
                      <img
                        src={getImageUrlForList(result.poster_path)}
                        className="movie-search__results-list-item-image image-cover"
                        alt={result.title}
                      />
                    )}
                  </div>
                  <div className="movie-search__results-list-item-content">
                    <h3 className="movie-search__results-list-item-title">
                      {result.title}
                    </h3>
                    {result.release_date !== undefined && (
                      <ListItemMetaDetails
                        meta={[convertReleaseDateToYear(result.release_date)]}
                      />
                    )}
                  </div>
                </button>
              </li>
            ))}
          </ul>
        )}
        {isLoading && (
          <div className="movie-search__results-loader">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export { MovieSearch };
