import { useState } from "react";
import { MovieProvider } from "../../@types/definitions";
import { convertMillisecondsToDateDisplay } from "../../helpers/dates";
import { getImageUrlForList } from "../../helpers/images";
import { useAppSelector } from "../../hooks/reduxAlias";
import "./providers-list.scss";

interface ProvidersListProps {
  providers: Array<MovieProvider>;
  updatedDate?: number | null;
}

const ProvidersList = (props: ProvidersListProps) => {
  const { providers, updatedDate } = props;
  const [showAll, setShowAll] = useState(false);
  const userPreferred = useAppSelector(state => state.user.settings.providers);
  const userHasChosenProviders = userPreferred !== null;
  const filteredProviders = userHasChosenProviders
    ? providers.filter(({ provider_id }) => userPreferred.includes(provider_id))
    : providers;
  const displayProviders = showAll ? providers : filteredProviders;
  const isAvailableElsewhere = filteredProviders.length < providers.length;

  const toggleShowAll = () => setShowAll(currentShow => !currentShow);

  return (
    <div className="providers">
      <h4 className="providers__title">Streaming Providers</h4>
      <p className="providers__data">
        Information provided by{" "}
        <a
          href="https://www.justwatch.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          JustWatch
        </a>
        .{" "}
        {updatedDate &&
          updatedDate !== null &&
          "Last updated " + convertMillisecondsToDateDisplay(updatedDate)}
      </p>
      {providers.length > 0 && filteredProviders.length === 0 && (
        <p>
          This film isn't currently available to stream on your preferred
          providers.
        </p>
      )}
      {displayProviders.length > 0 && (
        <ul className="providers__list">
          {displayProviders.map(provider => {
            const { provider_id, provider_name, logo_path } = provider;
            return (
              <li key={provider_id} className="providers__list-item">
                <img
                  src={getImageUrlForList(logo_path)}
                  alt={provider_name}
                  className="providers__list-item-image"
                />
              </li>
            );
          })}
        </ul>
      )}
      {providers.length === 0 && (
        <p>This film isn't currently available to stream.</p>
      )}
      {filteredProviders.length === 0 && isAvailableElsewhere && (
        <button
          className="providers__reveal"
          type="button"
          onClick={toggleShowAll}
        >
          {showAll ? "Hide" : "Show All"}
        </button>
      )}
    </div>
  );
};

export { ProvidersList };
