import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import { lists } from "./lists";
import { modal } from "./modal";
import { user } from "./user";
import { loaders } from "./loaders";

export const allReducers = (history: History<any>) =>
  combineReducers({
    router: connectRouter(history),
    lists,
    modal,
    user,
    loaders,
  });
