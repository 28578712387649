import { User } from "netlify-identity-widget";
import { AnyAction } from "redux";
import {
  AppThunk,
  FilmListUser,
  UserSettingsInState,
} from "../@types/definitions";
import { UserAction, UserSettingsAction } from "../constants";
import { listsStartedSyncing, listsStoppedSyncing } from "./loaders";

export const loginUser = (user: User): AnyAction => {
  return {
    type: UserAction.LogIn,
    user: {
      id: user.id,
      name: user.user_metadata.full_name,
      token: user.token?.access_token,
    } as FilmListUser,
  };
};

export const logoutUser = (): AnyAction => {
  return {
    type: UserAction.LogOut,
  };
};

export const authHasInit = (): AnyAction => {
  return {
    type: UserAction.AuthHasInit,
  };
};

export const addPreferedProvider = (providerId: Number): AppThunk => {
  return dispatch => {
    dispatch({
      type: UserSettingsAction.AddPreferedProvider,
      providerId,
    });

    dispatch(saveSettingsChanges());
  };
};

export const removePreferedProvider = (providerId: Number): AppThunk => {
  return dispatch => {
    dispatch({
      type: UserSettingsAction.RemovePreferedProvider,
      providerId,
    });

    dispatch(saveSettingsChanges());
  };
};

export const updateSettingsSynced = (synced: Number): AnyAction => {
  return {
    type: UserSettingsAction.UpdateSettingsSynced,
    synced,
  };
};

const saveSettingsChanges = (): AppThunk => {
  return (dispatch, getState) => {
    const token = getState().user.user.token;
    const settings = getState().user.settings;

    dispatch(listsStartedSyncing());

    window
      .fetch("/.netlify/functions/save-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(settings),
      })
      .then((response: Response) => response.json())
      .then(json => {
        const { ts } = json;
        dispatch(updateSettingsSynced(ts));
      })
      .finally(() => dispatch(listsStoppedSyncing()));
  };
};

export const loadSettings = (settings: UserSettingsInState): AnyAction => {
  return {
    type: UserSettingsAction.LoadSettings,
    settings,
  };
};
