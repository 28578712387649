import { ModalIdentifier } from "../../constants";
import { useModal } from "../../hooks/useModal";
import { AddMovieData } from "../../modals/add-movie/add-movie";
import "./add-film-button.scss";
import { Button, ButtonIcon, ButtonSize } from "../button/button";

interface AddFilmButtonProps {
  listId: string | null;
}

const AddFilmButton = (props: AddFilmButtonProps) => {
  const { listId } = props;
  const { openModal } = useModal();

  const openAddFilmModal = () => {
    openModal(ModalIdentifier.AddMovieModal, { listId } as AddMovieData);
  };

  return (
    <>
      <div className="add-film-button">
        <div className="add-film-button__container">
          <Button
            icon={ButtonIcon.Plus}
            size={ButtonSize.Large}
            onPress={openAddFilmModal}
          >
            Add Film
          </Button>
        </div>
      </div>
      <div className="add-film-button__spacer" />
    </>
  );
};

export { AddFilmButton };
