import { AnyAction } from "redux";
import { FilmListUser, UserSettingsInState } from "../@types/definitions";
import { UserAction, UserSettingsAction } from "../constants";

export interface UserState {
  user: FilmListUser;
  settings: UserSettingsInState;
  authInit: boolean;
}

export const defaultUser: UserState = {
  user: {
    id: null,
    name: null,
    token: null,
  },
  settings: {
    providers: null,
    synced: null,
  },
  authInit: false,
};

export const user = (
  state: UserState = { ...defaultUser },
  action: AnyAction,
) => {
  switch (action.type) {
    case UserAction.LogIn:
      return {
        ...state,
        user: action.user,
        authInit: true,
      };
    case UserAction.AuthHasInit:
      return {
        ...state,
        authInit: true,
      };
    case UserAction.LogOut:
      return {
        ...defaultUser,
        authInit: true,
      };
    case UserSettingsAction.AddPreferedProvider:
      return {
        ...state,
        settings: {
          ...state.settings,
          providers:
            state.settings.providers === null
              ? [action.providerId]
              : [...state.settings.providers, action.providerId],
        },
      };
    case UserSettingsAction.RemovePreferedProvider:
      return {
        ...state,
        settings: {
          ...state.settings,
          providers:
            state.settings.providers !== null
              ? state.settings.providers.filter(id => id !== action.providerId)
              : null,
        },
      };
    case UserSettingsAction.UpdateSettingsSynced:
      return {
        ...state,
        settings: {
          ...state.settings,
          synced: action.synced,
        },
      };
    case UserSettingsAction.LoadSettings:
      return {
        ...state,
        settings: action.settings,
      };
    default:
      return state;
  }
};
