import { StorageKeys } from "./constants";
import { defaultLists } from "./reducers/lists";
import { defaultUser } from "./reducers/user";

const lists = localStorage.getItem(StorageKeys.Lists);
const listSettings = localStorage.getItem(StorageKeys.ListSettings);
const userSettings = localStorage.getItem(StorageKeys.UserSetings);

export const initialState = {
  lists: {
    ...defaultLists,
    lists: lists !== null ? JSON.parse(lists) : [],
    listSettings: listSettings !== null ? JSON.parse(listSettings) : [],
  },
  user: {
    ...defaultUser,
    settings:
      userSettings !== null
        ? { ...defaultUser.settings, ...JSON.parse(userSettings) }
        : { ...defaultUser.settings },
  },
};
