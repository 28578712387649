import { MovieSortOption, SortDirection } from "../../constants";
import { ReactComponent as SortUp } from "@fortawesome/fontawesome-free/svgs/solid/sort-up.svg";
import { ReactComponent as SortDown } from "@fortawesome/fontawesome-free/svgs/solid/sort-down.svg";
import "./sort-bar.scss";

interface SortBarProps {
  activeSortOption: MovieSortOption;
  onSortOptionPress: (a: MovieSortOption) => void;
  activeDirection: SortDirection;
  onSortDirectionPress: () => void;
}

const SortBar = (props: SortBarProps) => {
  const {
    activeSortOption,
    onSortOptionPress,
    activeDirection,
    onSortDirectionPress,
  } = props;

  const sortOptions = Object.values(MovieSortOption);

  const sortUpClass =
    activeDirection === SortDirection.Ascending
      ? "sort-bar__direction-icon sort-bar__direction-icon--active"
      : "sort-bar__direction-icon";

  const sortDownClass =
    activeDirection === SortDirection.Descending
      ? "sort-bar__direction-icon sort-bar__direction-icon--active"
      : "sort-bar__direction-icon";

  return (
    <div className="sort-bar">
      <div className="sort-bar__name">Sort</div>
      <ul className="sort-bar__options">
        {sortOptions.map(sortOption => (
          <li key={sortOption} className="sort-bar__option">
            <button
              className="sort-bar__option-pressable"
              onClick={() => onSortOptionPress(sortOption)}
              disabled={activeSortOption === sortOption}
            >
              <div className="sort-bar__option-button">{sortOption}</div>
            </button>
            {activeSortOption === sortOption && (
              <div className="sort-bar__option-active" role="presentation" />
            )}
          </li>
        ))}
      </ul>
      <button
        className="sort-bar__direction-pressable"
        onClick={onSortDirectionPress}
      >
        <div className="sort-bar__direction-button">
          <SortUp className={sortUpClass} />
          <SortDown className={sortDownClass} />
        </div>
      </button>
    </div>
  );
};

export { SortBar };
