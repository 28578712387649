import * as NetlifyIdentityWidget from "netlify-identity-widget";
import { FilmListUser } from "../../@types/definitions";
import { Button, ButtonIcon, ButtonType } from "../../components/button/button";
import { ModalFooter } from "../../components/modal-footer/modal-footer";
import { Modal } from "../../components/modal/modal";
import { ProviderSelection } from "../../components/provider-selection/provider-selection";
import { useAppSelector } from "../../hooks/reduxAlias";
import { useModal } from "../../hooks/useModal";

const ProfileSettingsModal = () => {
  const user = useAppSelector(state => state.user.user) as FilmListUser;
  const { closeModal } = useModal();

  const startLogout = () => {
    if (window.confirm("Are you sure you want to log out?")) {
      NetlifyIdentityWidget.logout();
      closeModal();
    }
  };

  return (
    <Modal title="Profile Settings">
      <p>You are logged in as {user.name}</p>
      <p>Choose your prefered streaming providers:</p>
      <ProviderSelection />
      <ModalFooter>
        <Button
          type={ButtonType.SecondaryWarning}
          icon={ButtonIcon.Logout}
          onPress={startLogout}
        >
          Log out
        </Button>
        <Button onPress={closeModal}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export { ProfileSettingsModal };
