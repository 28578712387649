import { push } from "connected-react-router";
import {
  AppThunk,
  DatabaseSyncResponse,
  List,
  Movie,
  MovieDbMovie,
} from "../@types/definitions";
import {
  ListActions,
  ListSettingActions,
  MovieSortOption,
  SortDirection,
} from "../constants";
import { convertSettingsFromDatabase } from "../helpers/api";
import {
  convertListFromDatabaseToList,
  generateNewList,
  getAppropriateSlugForNewList,
} from "../helpers/lists";
import { listsStartedSyncing, listsStoppedSyncing } from "./loaders";
import { loadSettings } from "./user";

const saveListChanges = (list: List): AppThunk => {
  return (dispatch, getState) => {
    const token = getState().user.user.token;

    dispatch(listsStartedSyncing());

    window
      .fetch("/.netlify/functions/save-list", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(list),
      })
      .then((response: Response) => response.json())
      .then(json => {
        const { ref, ts } = json;
        const id = ref["@ref"].id;
        dispatch(updateListSynced(list.id, ts));
        if (list.id !== id) {
          dispatch(updateListId(list.id, id));
        }
      })
      .finally(() => dispatch(listsStoppedSyncing()));
  };
};

const getUpdatedListAndSave = (listId: string): AppThunk => {
  return (dispatch, getState) => {
    const updatedList = getState().lists.lists.find(list => list.id === listId);

    if (updatedList) {
      dispatch(saveListChanges(updatedList));
    }
  };
};

export const createNewList = (name: string): AppThunk => {
  return (dispatch, getState) => {
    const slug = getAppropriateSlugForNewList(name, getState().lists.lists);
    const list = generateNewList(name, slug);

    dispatch({
      type: ListActions.CreateNewList,
      list,
    });

    dispatch(saveListChanges(list));
  };
};

export const addMovieToList = (listId: string, movie: Movie): AppThunk => {
  return dispatch => {
    dispatch({
      type: ListActions.AddMovieToList,
      listId,
      movie,
    });

    dispatch(getUpdatedListAndSave(listId));
  };
};

export const changeListSortType = (
  listId: string,
  sortType: MovieSortOption,
) => {
  return {
    type: ListSettingActions.ChangeListSortType,
    listId,
    sortType,
  };
};

export const changeListSortDIrection = (
  listId: string,
  direction: SortDirection,
) => {
  return {
    type: ListSettingActions.ChangeListSortDirection,
    listId,
    direction,
  };
};

export const removeMovieFromList = (
  listId: string,
  movieId: number,
): AppThunk => {
  return dispatch => {
    dispatch({
      type: ListActions.RemoveMovieFromList,
      listId,
      movieId,
    });

    dispatch(getUpdatedListAndSave(listId));
  };
};

export const removeList = (listId: string): AppThunk => {
  return (dispatch, getState) => {
    const { token } = getState().user.user;
    const list = getState().lists.lists.find(list => list.id === listId);

    dispatch({
      type: ListActions.RemoveList,
      listId,
    });

    if (list?.synced !== null) {
      dispatch(listsStartedSyncing());

      window
        .fetch("/.netlify/functions/delete-list", {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({ listId }),
        })
        .finally(() => dispatch(listsStoppedSyncing()));
    }
  };
};

export const editMovie = (listId: string, movie: Movie): AppThunk => {
  return dispatch => {
    dispatch({
      type: ListActions.EditMovie,
      listId,
      movie,
    });

    dispatch(getUpdatedListAndSave(listId));
  };
};

export const toggleMovieWatchedStatus = (
  listId: string,
  movieId: number,
): AppThunk => {
  return dispatch => {
    dispatch({
      type: ListActions.ToggleMovieWatchedStatus,
      listId,
      movieId,
    });

    dispatch(getUpdatedListAndSave(listId));
  };
};

export const editList = (listId: string, list: Partial<List>): AppThunk => {
  return (dispatch, getState) => {
    const lists = getState().lists.lists;
    const thisList = lists.find(list => list.id === listId);
    const otherLists = lists.filter(list => list.id !== listId);

    const slug =
      list.name !== undefined
        ? getAppropriateSlugForNewList(list.name, otherLists)
        : undefined;

    dispatch({
      type: ListActions.EditList,
      list: {
        ...list,
        slug,
        id: listId,
      },
    });

    if (thisList && thisList.slug !== slug) {
      dispatch(push("/list/" + slug));
    }

    dispatch(getUpdatedListAndSave(listId));
  };
};

export const updateListId = (oldListId: string, newId: string): AppThunk => {
  return dispatch => {
    dispatch({
      type: ListActions.UpdateListId,
      oldListId,
      newId,
    });

    dispatch(getUpdatedListAndSave(newId));
  };
};

export const updateListSynced = (listId: string, synced: number) => {
  return {
    type: ListActions.UpdateListSynced,
    listId,
    synced,
  };
};

export const loadListsFromDatabase = (): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.user.user.token;
    const currentLists = state.lists.lists;

    dispatch(listsStartedSyncing());

    window
      .fetch("/.netlify/functions/get-lists", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((response: Response) => response.json())
      .then((response: DatabaseSyncResponse) => {
        const dbResults = response.lists.data;
        const settingsResult = response.settings;

        let toAdd: Array<List> = [];
        let toUpdate: Array<List> = [];
        let toDelete: Array<string> = [];

        dbResults.forEach(dbResult => {
          if (
            currentLists.find(list => list.id === dbResult.data.id) ===
            undefined
          ) {
            toAdd.push(convertListFromDatabaseToList(dbResult));
          }
        });

        currentLists.forEach(list => {
          const dbList = dbResults.find(result => result.data.id === list.id);

          if (dbList && list.synced !== null && dbList.ts > list.synced) {
            toUpdate.push(convertListFromDatabaseToList(dbList));
          } else if (dbList === undefined && list.synced !== null) {
            toDelete.push(list.id);
          }
        });

        if (toAdd.length > 0) {
          dispatch(loadLists(toAdd));
        }
        if (toUpdate.length > 0) {
          dispatch(updateLists(toUpdate));
        }
        if (toDelete.length > 0) {
          dispatch(deleteLists(toDelete));
        }
        if (settingsResult !== null) {
          dispatch(loadSettings(convertSettingsFromDatabase(settingsResult)));
        }
      })
      .finally(() => {
        dispatch(listsStoppedSyncing());
      });
  };
};

export const loadLists = (lists: Array<List>) => {
  return {
    type: ListActions.LoadLists,
    lists,
  };
};

export const updateLists = (lists: Array<List>) => {
  return {
    type: ListActions.UpdateLists,
    lists,
  };
};

export const deleteLists = (listIds: Array<string>) => {
  return {
    type: ListActions.DeleteLists,
    listIds,
  };
};

export const updateMovie = (listId: string, movieDbId: number): AppThunk => {
  return (dispatch, getState) => {
    const lists = getState().lists.lists;
    const list = lists.find(list => list.id === listId);
    const movie = list?.movies.find(movie => movie.movieDbId === movieDbId);

    if (
      movie?.updated === undefined ||
      new Date().getTime() - movie.updated > 24 * 60 * 60 * 1000
    ) {
      window
        .fetch("/.netlify/functions/movie?id=" + movieDbId)
        .then(response => response.json())
        .then((data: { movie: MovieDbMovie }) => {
          const { movie } = data;
          const converted = {
            movieDbId: movie.id,
            imageUrl: movie.poster_path,
            name: movie.title,
            runtime: movie.runtime,
            releaseDate: movie.release_date,
            providers: movie.providers,
          } as Partial<Movie>;

          dispatch({
            type: ListActions.UpdateMovie,
            movie: converted,
            listId,
          });

          dispatch(getUpdatedListAndSave(listId));
        })
        .catch(() => {
          // Do nothing.
        });
    }
  };
};
