import { Middleware } from "redux";
import {
  ListActions,
  ListSettingActions,
  StorageKeys,
  UserSettingsAction,
} from "../constants";
import { State } from "../store";

export const saveToStorage: Middleware = store => next => action => {
  const result = next(action);
  const state: State = store.getState();
  const listSettingActions = [
    ...Object.values(ListSettingActions),
    ListActions.CreateNewList,
    ListActions.RemoveList,
    ListActions.UpdateListId,
    ListActions.LoadLists,
    ListActions.DeleteLists,
  ];

  if (Object.values(ListActions).includes(action.type)) {
    const dataToSave = JSON.stringify(state.lists.lists);
    localStorage.setItem(StorageKeys.Lists, dataToSave);
  }

  if (Object.values(UserSettingsAction).includes(action.type)) {
    const dataToSave = JSON.stringify(state.user.settings);
    localStorage.setItem(StorageKeys.UserSetings, dataToSave);
  }

  if (listSettingActions.includes(action.type)) {
    localStorage.setItem(
      StorageKeys.ListSettings,
      JSON.stringify(state.lists.listSettings),
    );
  }

  return result;
};
