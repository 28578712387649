import React from "react";
import { Movie, MovieProvider } from "../../@types/definitions";
import { convertReleaseDateToYear } from "../../helpers/dates";
import { getImageUrlForList } from "../../helpers/images";
import { convertRuntimeToDisplay } from "../../helpers/time";
import { Button, ButtonIcon, ButtonType } from "../button/button";
import { ListItemMetaDetails } from "../list-item-meta-details/list-item-meta-details";
import { ProvidersList } from "../providers-list/providers-list";
import { SelectLists } from "../select-list/select-list";
import "./movie-form.scss";

interface MovieFormProps {
  id: number;
  name: string;
  releaseDate: string;
  imageUrl: string | null;
  runtime: number | null;
  added?: number;
  watchDate?: number;
  updated?: number | null;
  onSave: (a: Movie) => void;
  onDelete?: (a: number, b: string) => void;
  providers?: Array<MovieProvider>;
  saveText?: string;
  needToSelectList: boolean;
  onSelectList?: (a: string) => void;
  activeList?: string | null;
}

const MovieForm = (props: MovieFormProps) => {
  const {
    id,
    name,
    releaseDate,
    imageUrl,
    runtime,
    added: initialAdded,
    watchDate,
    updated,
    providers,
    onSave,
    onDelete,
    saveText,
    needToSelectList,
    onSelectList,
    activeList,
  } = props;

  let metaDetails = [convertReleaseDateToYear(releaseDate)];
  if (runtime !== null) {
    metaDetails.push(convertRuntimeToDisplay(runtime));
  }

  const onSubmitPress = (e: React.SyntheticEvent) => {
    e.preventDefault();

    onSave({
      movieDbId: id,
      name,
      releaseDate,
      runtime,
      added: initialAdded ?? new Date().getTime(),
      imageUrl,
      watchDate,
      providers,
    });
  };

  return (
    <form className="movie-form" onSubmit={onSubmitPress}>
      <div className="movie-form__movie">
        <div className="movie-form__movie-image-container poster-image-container">
          {imageUrl && (
            <img
              src={getImageUrlForList(imageUrl)}
              className="movie-form__movie-image image-cover"
              alt={name}
            />
          )}
        </div>
        <div className="movie-form__movie-content">
          <h3 className="movie-form__movie-title">{name}</h3>
          <ListItemMetaDetails meta={metaDetails} />
        </div>
      </div>
      {providers && (
        <ProvidersList providers={providers} updatedDate={updated} />
      )}
      {needToSelectList &&
        onSelectList !== undefined &&
        activeList !== undefined && (
          <div className="movie-form__select-list">
            <h4>Select a List</h4>
            <SelectLists onSelect={onSelectList} selectedListId={activeList} />
          </div>
        )}
      <div className="movie-form__actions">
        {onDelete !== undefined && (
          <Button
            onPress={() => onDelete(id, name)}
            icon={ButtonIcon.Bin}
            type={ButtonType.SecondaryWarning}
          >
            Delete
          </Button>
        )}
        <Button
          onPress="submit"
          icon={saveText !== undefined ? ButtonIcon.Save : ButtonIcon.Plus}
        >
          {saveText !== undefined ? saveText : "Add Film"}
        </Button>
      </div>
    </form>
  );
};

export { MovieForm };
