import { AnyAction } from "redux";
import { LoaderAction } from "../constants";

interface LoadersState {
  isSyncing: boolean;
}

const defaultLoaders: LoadersState = {
  isSyncing: false,
};

export const loaders = (
  state: LoadersState = { ...defaultLoaders },
  action: AnyAction,
): LoadersState => {
  switch (action.type) {
    case LoaderAction.ListsAreSyncing:
      return {
        ...state,
        isSyncing: true,
      };
    case LoaderAction.ListsStoppedSyncing:
      return {
        ...state,
        isSyncing: false,
      };
    default:
      return state;
  }
};
