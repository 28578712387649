import { Redirect, useParams } from "react-router";
import { ListWithSettings } from "../@types/definitions";
import { useAppSelector } from "../hooks/reduxAlias";
import { getListWithSettings } from "../selectors/lists";
import { ListScreen } from "./list-screen";

interface ListScreenRoute {
  id: string;
}

const ListScreenRouter = () => {
  const { id } = useParams<ListScreenRoute>();
  const list = useAppSelector(state => getListWithSettings(state, id)) as
    | ListWithSettings
    | undefined;

  if (list === undefined) {
    return <Redirect to="/" />;
  }

  return <ListScreen list={list} />;
};

export { ListScreenRouter };
