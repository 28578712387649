export const convertReleaseDateToYear = (releaseDate: string): string => {
  return releaseDate.split("-")[0];
};

export const convertMillisecondsToDateDisplay = (
  milliseconds: number,
): string => {
  const date = new Date();
  date.setTime(milliseconds);

  const day = date.getDate().toString();
  const month = date.getMonth() + 1;
  const year = date.getFullYear().toString();

  return day + "/" + month.toString() + "/" + year[2] + year[3];
};

export const convertReleaseDateToTime = (releaseDate: string): number => {
  const split = releaseDate.split("-");
  const year = parseInt(split[0]);
  const month = parseInt(split[1]) - 1;
  const date = parseInt(split[2]);

  return new Date(year, month, date).getTime();
};
