import { Link } from "../link/link";
import "./header.scss";
import { ReactComponent as LeftArrow } from "@fortawesome/fontawesome-free/svgs/solid/arrow-left.svg";
import { ReactComponent as DotsIcon } from "@fortawesome/fontawesome-free/svgs/solid/ellipsis-v.svg";
import { ReactComponent as UserCogIcon } from "@fortawesome/fontawesome-free/svgs/solid/cog.svg";

export enum HeaderMoreIcon {
  Dots = "Dots",
  UserSettings = "UserSettings",
}

interface HeaderProps {
  children: string;
  backLink?: string;
  moreIcon?: HeaderMoreIcon;
  moreButtonAction?: () => void;
}

const HeaderIcon = ({
  icon,
  className,
}: {
  icon: HeaderMoreIcon;
  className: string;
}): JSX.Element => {
  switch (icon) {
    case HeaderMoreIcon.UserSettings:
      return <UserCogIcon className={className} />;
    default:
      return <DotsIcon className={className} />;
  }
};

const Header = (props: HeaderProps) => {
  const { children, backLink, moreButtonAction, moreIcon } = props;
  const displayIcon = moreIcon ?? HeaderMoreIcon.Dots;

  return (
    <>
      <header className="site-header">
        {backLink ? (
          <Link link={backLink} className="site-header__button">
            <LeftArrow className="site-header__icon" />
          </Link>
        ) : (
          <div className="site-header__button" />
        )}
        <h1 className="site-header__title">{children}</h1>

        {moreButtonAction ? (
          <button className="site-header__button" onClick={moreButtonAction}>
            <HeaderIcon icon={displayIcon} className="site-header__icon" />
          </button>
        ) : (
          <div className="site-header__button" />
        )}
      </header>
      <div className="site-header__spacer" />
    </>
  );
};

export { Header };
