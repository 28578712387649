import { AnyAction } from "redux";
import { List, ListSettings } from "../@types/definitions";
import {
  ListActions,
  ListSettingActions,
  MovieSortOption,
  SortDirection,
} from "../constants";
import { createRandomId } from "../helpers/ids";
import { generateNewListSettings } from "../helpers/lists";

export interface ListState {
  lists: Array<List>;
  listSettings: Array<ListSettings>;
}

export const defaultLists: ListState = {
  lists: [
    {
      id: createRandomId(),
      name: "My Film List",
      slug: "my-film-list",
      movies: [],
      synced: null,
    },
  ],
  listSettings: [],
};

export const lists = (
  state = { ...defaultLists },
  action: AnyAction,
): ListState => {
  switch (action.type) {
    case ListActions.CreateNewList:
      return {
        ...state,
        lists: [...state.lists, action.list],
        listSettings: [
          ...state.listSettings,
          generateNewListSettings(action.list.id),
        ],
      };
    case ListActions.AddMovieToList:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              movies: [
                ...list.movies,
                { ...action.movie, updated: new Date().getTime() },
              ],
            };
          }
          return list;
        }),
      };
    case ListSettingActions.ChangeListSortType:
      return {
        ...state,
        listSettings: state.listSettings.map(settings => {
          if (settings.listId === action.listId) {
            return {
              ...settings,
              activeSortType: {
                ...settings.activeSortType,
                type: action.sortType as MovieSortOption,
              },
            };
          }
          return settings;
        }),
      };
    case ListSettingActions.ChangeListSortDirection:
      return {
        ...state,
        listSettings: state.listSettings.map(settings => {
          if (settings.listId === action.listId) {
            return {
              ...settings,
              activeSortType: {
                ...settings.activeSortType,
                direction: action.direction as SortDirection,
              },
            };
          }
          return settings;
        }),
      };
    case ListActions.RemoveMovieFromList:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              movies: list.movies.filter(
                movie => movie.movieDbId !== action.movieId,
              ),
            };
          }
          return list;
        }),
      };
    case ListActions.RemoveList:
      return {
        ...state,
        lists: state.lists.filter(list => list.id !== action.listId),
        listSettings: state.listSettings.filter(
          settings => settings.listId !== action.listId,
        ),
      };
    case ListActions.EditMovie:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              movies: list.movies.map(movie => {
                if (movie.movieDbId === action.movie.movieDbId) {
                  return action.movie;
                }
                return movie;
              }),
            };
          }
          return list;
        }),
      };
    case ListActions.ToggleMovieWatchedStatus:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              movies: list.movies.map(movie => {
                if (movie.movieDbId === action.movieId) {
                  return {
                    ...movie,
                    watchDate:
                      movie.watchDate === undefined
                        ? new Date().getTime()
                        : undefined,
                  };
                }
                return movie;
              }),
            };
          }
          return list;
        }),
      };
    case ListActions.EditList:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.list.id) {
            return {
              ...list,
              ...action.list,
            };
          }
          return list;
        }),
      };
    case ListActions.UpdateListId:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.oldListId) {
            return {
              ...list,
              id: action.newId,
            };
          }
          return list;
        }),
        listSettings: state.listSettings.map(settings => {
          if (settings.listId === action.oldListId) {
            return {
              ...settings,
              listId: action.newId,
            };
          }
          return settings;
        }),
      };
    case ListActions.UpdateListSynced:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              synced: action.synced,
            };
          }
          return list;
        }),
      };
    case ListActions.LoadLists:
      return {
        ...state,
        lists: [...state.lists, ...action.lists],
        listSettings: [
          ...state.listSettings,
          ...action.lists.map((list: List) => {
            return generateNewListSettings(list.id);
          }),
        ],
      };
    case ListActions.UpdateLists:
      return {
        ...state,
        lists: state.lists.map(list => {
          const updatedList = action.lists.find(
            (updatedList: List) => updatedList.id === list.id,
          );
          if (updatedList) {
            return updatedList;
          }
          return list;
        }),
      };
    case ListActions.DeleteLists:
      return {
        ...state,
        lists: state.lists.filter(list => {
          return !action.listIds.includes(list.id);
        }),
        listSettings: state.listSettings.filter(settings => {
          return !action.listIds.includes(settings.listId);
        }),
      };
    case ListActions.UpdateMovie:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              movies: list.movies.map(movie => {
                if (movie.movieDbId === action.movie.movieDbId) {
                  return {
                    ...movie,
                    ...action.movie,
                    updated: new Date().getTime(),
                  };
                }
                return movie;
              }),
            };
          }
          return list;
        }),
      };
    default:
      return state;
  }
};
