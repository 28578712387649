import "./section.scss";

interface SectionProps {
  children: any;
}

const Section = (props: SectionProps) => {
  const { children } = props;
  return <section className="standard-section">{children}</section>;
};

export { Section };
