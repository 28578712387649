import { ReactComponent as SyncIcon } from "@fortawesome/fontawesome-free/svgs/solid/sync-alt.svg";
import "./sync-indicator.scss";
import { useAppSelector } from "../../hooks/reduxAlias";

const SyncIndicator = () => {
  const isLoading = useAppSelector(state => state.loaders.isSyncing);

  if (!isLoading) {
    return null;
  }

  return (
    <div className="sync-indicator" title="Syncing...">
      <SyncIcon className="sync-indicator__icon" />
    </div>
  );
};

export { SyncIndicator };
