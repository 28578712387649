import { ModalIdentifier } from "./constants";
import { useAppSelector } from "./hooks/reduxAlias";
import { AddMovieModal } from "./modals/add-movie/add-movie";
import { EditListModal } from "./modals/edit-list/edit-list";
import { EditMovieModal } from "./modals/edit-movie/edit-movie";
import { ProfileSettingsModal } from "./modals/profile-settings/profile-settings";

const ModalManager = () => {
  const activeModal = useAppSelector(state => state.modal.active);

  switch (activeModal) {
    case ModalIdentifier.AddMovieModal:
      return <AddMovieModal />;
    case ModalIdentifier.EditMovieModal:
      return <EditMovieModal />;
    case ModalIdentifier.EditListModal:
      return <EditListModal />;
    case ModalIdentifier.ProfileSettingsModal:
      return <ProfileSettingsModal />;
    default:
      return null;
  }
};

export { ModalManager };
