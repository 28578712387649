import { Movie } from "../../@types/definitions";
import { convertMillisecondsToDateDisplay } from "../../helpers/dates";
import { getImageUrlForList } from "../../helpers/images";
import { convertRuntimeToDisplay } from "../../helpers/time";
import { ListItemMetaDetails } from "../list-item-meta-details/list-item-meta-details";
import { ReactComponent as TickIcon } from "@fortawesome/fontawesome-free/svgs/solid/check.svg";
import { ReactComponent as CrossIcon } from "@fortawesome/fontawesome-free/svgs/solid/times.svg";
import "./movie-list.scss";
import { useAppSelector } from "../../hooks/reduxAlias";

interface MovieListProps {
  movies: Array<Movie>;
  onEditMovie: (a: Movie) => void;
  onToggleWatched: (a: number) => void;
}

const MovieList = (props: MovieListProps) => {
  const { movies, onEditMovie, onToggleWatched } = props;
  const preferredProviders = useAppSelector(
    state => state.user.settings.providers,
  );

  return (
    <ul className="movies__list">
      {movies.map(movie => {
        const {
          movieDbId,
          name,
          imageUrl,
          runtime,
          watchDate,
          providers,
        } = movie;
        const displayProviders =
          preferredProviders !== null
            ? providers?.filter(provider =>
                preferredProviders.includes(provider.provider_id),
              )
            : providers;

        let metaDetails = [];
        if (runtime !== null) {
          metaDetails.push(convertRuntimeToDisplay(runtime));
        }
        if (displayProviders && displayProviders.length > 0) {
          let provider = displayProviders[0].provider_name;
          if (displayProviders.length > 1) {
            provider += " (+" + (displayProviders.length - 1) + ")";
          }
          metaDetails.push(provider);
        }

        return (
          <li className="movies__list-item" key={name}>
            <span
              className="movies__list-item-locator"
              id={movieDbId.toString()}
            />
            <button
              className="movies__list-item-pressable"
              onClick={() => onEditMovie(movie)}
            >
              <div className="movies__list-item-image-container poster-image-container">
                {imageUrl !== null && (
                  <img
                    className="movies__list-item-image image-cover"
                    src={getImageUrlForList(imageUrl)}
                    alt={name}
                  />
                )}
              </div>
              <div className="movies__list-item-content">
                <h3 className="movies__list-item-title">{name}</h3>
                {movie.watchDate !== undefined ? (
                  <ListItemMetaDetails
                    meta={[
                      "Watched: " +
                        convertMillisecondsToDateDisplay(movie.watchDate),
                    ]}
                  />
                ) : (
                  <ListItemMetaDetails meta={metaDetails} />
                )}
              </div>
            </button>
            <button
              className="movies__list-item-watch-action"
              onClick={() => onToggleWatched(movieDbId)}
            >
              {watchDate !== undefined ? (
                <CrossIcon className="movies__list-item-action-icon" />
              ) : (
                <TickIcon className="movies__list-item-action-icon" />
              )}
            </button>
          </li>
        );
      })}
    </ul>
  );
};

export { MovieList };
