import { DisplayRuntime } from "../@types/definitions";

export const convertHoursAndMinutesToRuntime = (
  hours: number,
  minutes: number,
): number => {
  return hours * 60 + minutes;
};

export const convertRuntimeToHoursAndMintes = (
  runtime: number,
): DisplayRuntime => {
  return {
    hours: Math.floor(runtime / 60),
    minutes: runtime % 60,
  };
};

export const convertRuntimeToDisplay = (runtime: number): string => {
  const { hours, minutes } = convertRuntimeToHoursAndMintes(runtime);
  let str = "";

  if (hours > 0) {
    str += hours + "hr";
    if (hours !== 1) {
      str += "s";
    }
    str += " ";
  }

  str += minutes + "min";

  if (minutes !== 1) {
    str += "s";
  }

  return str;
};
