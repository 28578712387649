import { useModal } from "../../hooks/useModal";
import "./modal.scss";
import { ReactComponent as CrossIcon } from "@fortawesome/fontawesome-free/svgs/solid/times.svg";
import { useEffect } from "react";
import { history } from "../../store";

interface ModalProps {
  children: any;
  title: string;
}

const Modal = (props: ModalProps) => {
  const { children, title } = props;
  const { closeModal } = useModal();

  useEffect(() => {
    const id = "#" + title.toLowerCase().replaceAll(" ", "-");

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape" || e.key === "Esc") {
        closeModal();
      }
    };

    const onBackButton = (e: PopStateEvent) => {
      closeModal();
    };

    history.push(id);

    document.addEventListener("keydown", onKeyDown);
    window.addEventListener("popstate", onBackButton);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("popstate", onBackButton);
      if (window.location.hash.includes(id)) {
        history.goBack();
      }
    };
  }, [closeModal, title]);

  return (
    <div className="modal">
      <div className="modal__overlay" onClick={closeModal} />
      <div className="modal__container">
        <div className="modal__header">
          {title && <h2 className="modal__title">{title}</h2>}
          <button className="modal__close" onClick={closeModal}>
            <CrossIcon className="modal__close-icon" />
          </button>
        </div>
        <div className="modal__content">{children}</div>
      </div>
    </div>
  );
};

export { Modal };
