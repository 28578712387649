import {
  MovieDbSearchResult,
  PromiseWithCancel,
  SettingsApiResponse,
  UserSettingsInState,
} from "../@types/definitions";

export const fetchSearchResults = (query: string): PromiseWithCancel<any> => {
  const abortController = new AbortController();
  const signal = abortController.signal;

  const search = new Promise((resolve, reject) => {
    fetch("/.netlify/functions/search?query=" + query, {
      method: "get",
      signal,
    })
      .then(response => response.json())
      .then(data => {
        resolve(data.results as Array<MovieDbSearchResult>);
      })
      .catch(reject);
  });

  (search as PromiseWithCancel<any>).cancel = () => abortController.abort();
  return search as PromiseWithCancel<any>;
};

export const isAbortError = (err: DOMException) => {
  if (err && err.name === "AbortError") {
    return true;
  }
  return false;
};

export const convertSettingsFromDatabase = (
  settings: SettingsApiResponse,
): UserSettingsInState => {
  const {
    ts,
    data: { owner, ...rest },
  } = settings;

  return {
    ...rest,
    synced: ts,
  };
};
