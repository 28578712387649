import { useCallback } from "react";
import { closeModal, openModal } from "../actions/modal";
import { ModalIdentifier } from "../constants";
import { useAppDispatch } from "./reduxAlias";

export const useModal = () => {
  const dispatch = useAppDispatch();
  const open = useCallback(
    (modalId: ModalIdentifier, data: any = null) =>
      dispatch(openModal(modalId, data)),
    [dispatch],
  );
  const close = useCallback(() => dispatch(closeModal()), [dispatch]);

  return {
    openModal: open,
    closeModal: close,
  };
};
