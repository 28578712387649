import { EditMovieData, ListWithSettings, Movie } from "../@types/definitions";
import { State } from "../store";

export const getListWithSettings = (
  state: State,
  listSlug: string,
): ListWithSettings | undefined => {
  const { lists, listSettings } = state.lists;
  const list = lists.find(list => list.slug === listSlug);

  const thisListSettings =
    list !== undefined
      ? listSettings.find(settings => settings.listId === list.id)
      : undefined;

  if (list !== undefined && thisListSettings !== undefined) {
    return {
      ...list,
      activeSortType: thisListSettings.activeSortType,
    };
  }

  return undefined;
};

export const getMovieDetailsForEdit = (state: State): Movie | null => {
  const editMovie = state.modal.data as EditMovieData;

  if (editMovie) {
    const list = state.lists.lists.find(list => list.id === editMovie.listId);
    const movie = list?.movies.find(
      movie => movie.movieDbId === editMovie.movie.movieDbId,
    );

    return movie ?? null;
  }

  return null;
};
