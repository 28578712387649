import { useEffect } from "react";
import { useAppSelector } from "../../hooks/reduxAlias";

interface SelectListProps {
  onSelect: (a: string) => void;
  selectedListId: string | null;
}

const SelectLists = (props: SelectListProps) => {
  const { onSelect, selectedListId } = props;
  const lists = useAppSelector(state => state.lists.lists);
  const selectedListDetails =
    selectedListId !== null
      ? lists.find(list => list.id === selectedListId)
      : undefined;

  useEffect(() => {
    if (selectedListId === null && lists.length > 0) {
      onSelect(lists[0].id);
    }
  }, [selectedListId, lists, onSelect]);

  if (lists.length === 0) {
    return <p>You have no lists.</p>;
  }

  if (lists.length === 1) {
    if (selectedListDetails !== undefined) {
      return <p>Adding to "{selectedListDetails.name}"</p>;
    }
    return null;
  }

  return (
    <select onChange={e => onSelect(e.target.value)}>
      {lists.map(list => (
        <option
          key={list.id}
          value={list.id}
          selected={list.id === selectedListId}
        >
          {list.name}
        </option>
      ))}
    </select>
  );
};

export { SelectLists };
