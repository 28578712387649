import { LoaderAction } from "../constants";

export const listsStartedSyncing = () => {
  return {
    type: LoaderAction.ListsAreSyncing,
  };
};

export const listsStoppedSyncing = () => {
  return {
    type: LoaderAction.ListsStoppedSyncing,
  };
};
